
          @import "src/styles/global";
        

.TrackRecordContainer {   
    padding: 50px 0;
}

.TrackRecordContent {
    margin: 0 auto;
    max-width: 1314px;
}

.TopTitle {
    @apply font-roslindale text-white;
    font-size: 36px;
    font-weight: 500;
    line-height: 137%;
    margin: 0 auto;
    text-align: center;
    &.runAnimation {
        animation: FadeFromBottomWithDelay 0.7s linear;
    }

    @media screen and (min-width: 998px) {
        font-size: 44px;
        margin: 0 20px 0 0;
    }
}

.TopDescription {
    @apply font-favorite text-gray-300;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    margin: 24px auto 40px;
    max-width: 340px;
    text-align: center;
    @media screen and (min-width: 998px) {
        font-size: 18px;
        max-width: 700px;
    }
    &.runAnimation {
        animation: FadeFromBottomWithDelay 0.5s linear;
    }
}

.SlickWrapper {
    padding-top: 0px;
    overflow: hidden;
    &.runAnimation {
        animation: testimonialsBottomFade 0.9s linear;

        @keyframes testimonialsBottomFade {
            0% {
                transform: translateY(20%);
            }

            100% {
                transform: translateY(0%);
            }
        }
    }

    &.row3 {
        margin: 0 auto;
        max-width: 1331px !important;
    }

    .full {
        padding-bottom: 50px;
        max-width: 100% !important;
        margin: 0 auto;
    }
    
    @media screen and (max-width: 991px) {
        .container {
            max-width: 100%;
        }
    }
}

.TrackrecordSliderContainer {
    margin: 0 auto;
    max-width: 1100px;
    padding: 0 30px;
    @media screen and (min-width: 998px) {
        padding: 0;
        &:before, &:after {
            background-color: #0F0F11;
            content: " ";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 110px;
            z-index: 1;
        }
        &:before {
            left: -110px;
        }
        &:after {
            right: -110px;
        }
    }
    :global {    
        .slick-list {
            overflow: visible;
        }

        // ${sliderDots("#242E35")};
        .slick-dots li button:before {
            font-size: 18px !important;
            opacity: 1;
            color: transparent;
            border: 2px solid #fff;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            content: "";
        }

        .slick-dots li.slick-active button:before {
            background-color: #fff;
            color: #fff;
            opacity: 1;
        }
        // removeOutline
        .slideWrap:focus,
        .slideWrap:active {
            outline: none;
        }
        
        .slick-next {
            right: -25px;
            z-index: 2;
        }

        .slick-prev {
            left: -25px;
            z-index: 2;
        }

        .slick-slide div {
            outline: 0;
        }
        .slick-track {
            display: flex;
        }
        .slick-slide {
            min-width: 330px;
            @media screen and (min-width: 430px) {
                min-width: 370px;
            }
            @media screen and (min-width: 998px) {
                min-width: 400px;
            }
        }

        .slick-arrow {
            font-size: 0;
            position: absolute;
            top: 35%;
            transform: translateY(-50%);
        }

        .slick-arrow:before {
            font-size: 20px;
        }
        
        .slick-next {
            right: -20px;
            z-index: 2;
        }

        .slick-prev {
            left: -60px;
            z-index: 2;
        }
        
        .slick-prev:before, .slick-next:before {
            @apply font-vinovestmedium bg-platinum-850;
            border: 1px solid #29292E;
            border-radius: 10px;
            font-size: 20px !important;
            line-height: 1;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            padding: 10px 12px;
            position: absolute;
            z-index: 2;
        }
        .slick-next:before {
            content: '→';
        }
        .slick-prev:before {
            content: '←';
        }

        .slick-dots {
            left: 0;
            bottom: -50px !important;
            li {
                button {
                    &:before {
                        background-color: #29292E;
                        border-color: #29292E;
                    }
                }
                &.slick-active {
                    button {
                        &:before {
                            background-color: #fff;
                            border-color: #fff;
                        }
                    }
                }
            }
        }

    }
}


.SlideContainer {
    @apply font-roslindale bg-black;
    align-items: start;
    color: #c5d5e4;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    margin-right: 10px;
    margin-left: 10px;
    width: 310px;
    position: relative;
    @media screen and (min-width: 430px) {
        width: 350px;
    }
    @media screen and (min-width: 998px) {
        font-size: 26px;
        margin-right: 20px;
        margin-left: 20px;
        min-width: 335px;
        padding: 40px;
        width: auto;
    }
    &.clean{
        align-items: stretch;
        border: 1px solid #000;
        border-radius: 30px;
        flex-flow: column;
        min-height: 336px;
        padding: 30px 20px;
        position: relative;
        @media screen and (min-width: 998px) {
            flex-flow: row;
            min-height: 370px;
            padding: 30px 0;
        }
        :global {
            div {
                @media screen and (min-width: 998px) {
                    width: 50%;
                }
            }
            h3 {
                @apply font-vinovestmedium;
                color: #fff;
                font-size: 14px;
                height: 40px;
                line-height: 1.3;
                min-width: 100%;
                @media screen and (min-width: 998px) {
                    height: 60px;
                }
            }
            .details {
                line-height: 1.2;
                width: 28%;
                &:first-of-type {
                    width: 44%;
                    @media screen and (min-width: 998px) {
                        width: 100%;
                    }
                }
                @media screen and (min-width: 998px) {
                    width: 100%;
                }
            }
            .text {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                padding: 10px 0 0;
                @media screen and (min-width: 998px) {
                    border-left: 1px solid #1A1A1A; 
                    flex-flow: column;
                    padding: 0 30px;
                }
            }
            .title {
                @apply font-vinovestmedium;
                font-size: 12px;
                margin-bottom: 0;
            }
            .value {
                color: #fff;
                margin-bottom: 10px;
            }
            .image {
                align-items: center;
                display: flex;
                height: 276px;
                justify-content: center;
                img {
                    max-height: 276px;
                    width: auto;
                }
            }
        }
    }
}

@keyframes FadeFromBottomWithDelay { 
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  50% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

