
          @import "src/styles/global";
        
.HeroDesktopContainer {
    position: relative;
    overflow: hidden;
}

.TitlesSection {
    align-items: center;
    color: #efddc7;
    display: flex;
    flex-direction: row;
    justify-items: center;
    margin: 0 auto;
    max-width: 1730px;
    &.runAnimation {
        position: relative;
        opacity: 1;
        animation: titlesSectionAnimation 0.5s ease-in-out;
    }

    :global {
        .titlesWrapperContainer {
            margin: 150px auto 0;
            position: relative;
            width: 100%;
            z-index: 0;
            &:before {
                @apply bg-brown-800;
                content: ' ';
                display: none;
                filter: blur(80px);
                height: 50px;
                left: 26%;
                opacity: .8;
                position: absolute;
                top: 230px;
                width: 500px;
                @media screen and (min-width: 998px) {
                    display: block;
                }
            }
            @media screen and (min-width: 768px) {
                padding-top: 300px;
            }
            @media screen and (min-width: 998px) {
                margin: 0 auto;
                padding-top: 0;
            }
        }

        h1 {
            color: #F8F8F8;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        .titlesWrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            justify-items: center;
            margin: 0 auto;
            max-width: 895px;
            padding: 0 20px;
            text-align: center;
            @media screen and (min-width: 768px) {
                padding: 0 0 40px;
            }
            @media screen and (min-width: 998px) {
                height: 1000px;
                margin-bottom: 100px;
                padding: 0;
            }
            @media screen and (max-height: 998px) and (min-width: 1024px) {
                height: 700px;
            }
        }

    }
}

.bottle {
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    :global {
        span {
            @apply  text-gray-400 border-b-[1px] border-gray-400;
            display: block;
            font-size: 18px;
            line-height: 1.3;
            position: absolute;
            text-align: right;
            width: 80px;
            &:after {
                @apply bg-gray-400;
                bottom: 0;
                content: ' ';
                display: block;
                height: 40px;
                left: -14px;
                position: absolute;
                width: 1px;
            }
            em {
                font-size: 12px;
                font-style: normal;
                display: block;
                padding-bottom: 10px;
            }
        }
    }
}

.bottle1 {
    background-image: url(../../../../../assets/img/home/dark/hero/bottle-1.png);
    height: 240px;
    left: -13%;
    opacity: 0;
    top: 400px;
    width: 170px;
    &.runAnimation {
        animation: bottle1 1s linear;
        opacity: 1;
    }
    @media screen and (min-width: 768px) {
        left: -1%;
        top: 220px;
    }
    @media screen and (min-width: 998px) {
        height: 650px;
        left: 6%;
        top: 146px;
        width: 400px;
    }
    @media screen and (max-height: 998px) and (min-width: 1024px) {
        height: 480px;
        width: 400px;
    }
    :global {
        span {
            right: -29px;
            top: 115px;
            &:after {
                bottom: -6px;
                transform: rotate(-45deg);
            }
            @media screen and (min-width: 998px) {
                right: 90px;
                top: 400px;
            }
            @media screen and (max-height: 998px) and (min-width: 1024px) {
                right: 120px;
                top: 300px;
            }
        }
    }
}
.bottle2 {
    background-image: url(../../../../../assets/img/home/dark/hero/bottle-2.png);
    height: 250px;
    left: 10%;
    opacity: 0;
    top: 280px;
    width: 260px;
    &.runAnimation {
        animation: bottle2 1s linear;
        opacity: 1;
    }
    @media screen and (min-width: 768px) {
        left: 27%;
        top: -20px;
    }
    @media screen and (min-width: 998px) {
        height: 800px;
        left: 20%;
        top: -70px;
        width: 800px;
    }
    @media screen and (max-height: 998px) and (min-width: 1024px) {
        height: 480px;
        width: 600px;
    }
    :global {
        span {
            right: 31px;
            top: -15px;
            &:after {
                bottom: -34px;
                transform: rotate(45deg);
            }
            @media screen and (min-width: 998px) {
                right: 55px;
                top: 342px;
            }
            @media screen and (max-height: 998px) and (min-width: 1024px) {
                right: 55px;
                top: 230px;
            }
        }
    }
}
.bottle3 {
    background-image: url(../../../../../assets/img/home/dark/hero/bottle-3.png);
    height: 100px;
    left: 85%;
    opacity: 0;
    top: 300px;
    width: 200px;
    &.runAnimation {
        animation: bottle3 1s linear;
        opacity: 1;
    }
    @media screen and (min-width: 768px) {
        height: 200px;
        left: 72%;
        top: 100px;
        width: 200px;
    }
    @media screen and (min-width: 998px) {
        height: 300px;
        left: 68%;
        top: 100px;
        width: 300px;
    }
    :global {
        span {
            right: 0;
            top: 0;
            &:after {
                bottom: -34px;
                transform: rotate(45deg);
            }

            @media screen and (min-width: 768px) {
                right: 0px;
                top: 30px;
            }
            @media screen and (min-width: 998px) {
                right: 80px;
                top: 20px;
            }
        }
    }
}

.bottle4 {
    background-image: url(../../../../../assets/img/home/dark/hero/bottle-4.png);
    height: 162px;
    left: 68%;
    opacity: 0;
    top: 560px;
    width: 160px;
    &.runAnimation {
        animation: bottle4 1s linear;
        opacity: 1;
    }
    @media screen and (min-width: 768px) {
        left: 80%;
        top: 400px;
    }
    @media screen and (min-width: 998px) {
        height: 300px;
        left: 78%;
        top: 500px;
        width: 300px;
    }
    span {
        right: 120px;
        top: 0px;
        &:after {
            bottom: -35px;
            left: auto;
            right: -14px;
            transform: rotate(-45deg);
            @media screen and (min-width: 998px) {
                bottom: -6px;
                left: -14px;
                right: auto;
                transform: rotate(-45deg);
            }
        }
        @media screen and (min-width: 998px) {
            right: 20px;
            top: 170px;
        }
    }
}

.HeroTitle {
    text-align: left;
    &.runAnimation {
        position: relative;
        animation: bottomAppearAnimation 0.8s linear;
    }
}

.HeroTitleHome {
    @apply text-gray-250 font-roslindale;
    font-size: 36px;
    line-height: 1.16;
    text-align: center;
    opacity: 0;
    @media screen and (min-width: 998px) {
        font-size: 64px;
        text-align: left;
    }
    @media screen and (max-width: 1279px) {
        font-size: 58px;
    }
    @media (max-width: 1023px) {
        font-size: 50px;
    }
    @media screen and (max-width: 997px) {
        font-size: 36px;
    }

    &.runAnimation {
        position: relative;
        opacity: 1;
        animation: bottomAppearAnimation 1s linear;
    }
}

.HeroDescription {
    @apply  text-gray-400;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 22px;
    margin-bottom: 42px;
    opacity: 0;
    text-align: center;
    width: 280px;
    @media screen and (min-width: 998px) {
        text-align: left;
        width: auto;
    }
    &.runAnimation {
        position: relative;
        opacity: 1;
        animation: bottomAppearAnimation 1s linear;
    }
}


.HeroButton {
    margin: 0 auto;
    &.runAnimation {
        position: relative;
        animation: bottomAppearAnimation 1s linear;
    }
    @media screen and (min-width: 998px) {
        margin: 0;
    }
}

.Stats {
    &.runAnimation {
        animation: bottomAppearAnimation 1.2s linear;
    }
}

@keyframes bottomAppearAnimation {    
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    50% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes titlesSectionAnimation {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}


@keyframes bottle1 {    
    0% {
        transform: translate(-300px, 300px);
        opacity: 0;
    }

    50% {
        transform: translate(-150px, 150px);
        opacity: .5;
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes bottle2 {    
    0% {
        transform: translate(300px, -800px);
        opacity: 0;
    }

    50% {
        transform: translate(150px, -400px);
        opacity: .5;
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes bottle3 {    
    0% {
        transform: translate(400px, -300px);
        opacity: 0;
    }

    50% {
        transform: translate(200px, -150px);
        opacity: .5;
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}


@keyframes bottle4 {    
    0% {
        transform: translate(600px, -200px);
        opacity: 0;
    }

    50% {
        transform: translate(300px, -100px);
        opacity: .5;
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}
