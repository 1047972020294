
          @import "src/styles/global";
        

.HistoryContainer {   
    padding: 50px 30px;
    @media screen and (min-width: 998px) {
        padding: 50px 0;
    }
}

.HistoryContent {
    margin: 0 auto;
    max-width: 1100px;
}

.TopContainer {
    margin-bottom: 90px;
}

.TopTitle {
    @apply font-roslindale text-white;
    font-size: 36px;
    font-weight: 500;
    line-height: 137%;
    margin: 0 auto;
    text-align: center;
    &.runAnimation {
        animation: FadeFromBottomWithDelay 0.7s linear;
    }

    @media screen and (min-width: 998px) {
        font-size: 44px;
        margin: 0 20px 0 0;
    }
}

.TopDescription {
    @apply font-favorite text-gray-300;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    margin: 24px auto 40px;
    max-width: 700px;
    text-align: center;
    @media screen and (min-width: 998px) {
        font-size: 18px;
    }
    &.runAnimation {
        animation: FadeFromBottomWithDelay 0.5s linear;
    }
}

.rowContainer {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin-bottom: 90px;
    @media screen and (min-width: 998px) {
        flex-flow: row;
    }
    :global {
        div {
            @media screen and (min-width: 998px) {
                width: 50%;
            }
        }
        .image {
            margin-bottom: 20px;
            order: 1;
            @media screen and (min-width: 998px) {
                margin-bottom: 0;
                order: initial;
            }
        }
        .text {
            order: 2;
            @media screen and (min-width: 998px) {
                order: initial;
                padding-right: 80px;
            }
        }
        .pl {
            @media screen and (min-width: 998px) {
                padding-left: 80px;
            }
        }
    }
}

.SectionTitle {
    @apply font-roslindale;
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    @media screen and (min-width: 998px) {
        font-size: 32px;
        margin-bottom: 20px;
        text-align: left;
    }
    &.runAnimation {
        animation: FadeFromBottomWithDelay 1s linear;
    }
}

.SectionDescription {
    @apply text-gray-300;
    font-size: 14px;
    text-align: center;
    @media screen and (min-width: 998px) {
        font-size: 20px;
        text-align: left;
    }
    &.runAnimation {
        animation: FadeFromBottomWithDelay 1s linear;
    }
}

@keyframes FadeFromBottomWithDelay { 
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  50% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

