
          @import "src/styles/global";
        
.Wrapper {
    margin: 50px auto 0;
    max-width: 1484px;
    padding: 50px 20px 100px;
    text-align: center;
    :global {
        h2 {
            @apply text-white font-roslindale;
            font-size: 32px;
            margin-bottom: 20px;
            max-width: 545px;
            @media only screen and (min-width: 987px) {
                font-size: 64px;
            }
        }
    }
}

.Container {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
}