
          @import "src/styles/global";
        

.ProductsBenefitsContainer {   
    padding: 50px 20px 0;
    @media screen and (min-width: 998px) {
        padding: 50px 0;
    }
}

.ProductsBenefitsContent {
    margin: 0 auto;
    max-width: 1314px;
}

.TopTitle {
    @apply font-roslindale text-white;
    font-size: 36px;
    font-weight: 500;
    line-height: 137%;
    margin: 0 auto;
    text-align: center;
    &.runAnimation {
        animation: FadeFromBottomWithDelay 0.7s linear;
    }

    @media screen and (min-width: 998px) {
        font-size: 44px;
        margin: 0 20px 0 0;
    }
}

.TopDescription {
    @apply font-favorite text-gray-300;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    margin: 24px auto 40px;
    max-width: 700px;
    text-align: center;
    @media screen and (min-width: 998px) {
        font-size: 18px;
    }
    &.runAnimation {
        animation: FadeFromBottomWithDelay 0.5s linear;
    }
}

.TopContentContainer {
    :global {
        img {
            margin: 70px auto;
            max-width: 788px;
            width: 100%;
        }
    }
}

.BenefitsList {
    color: #fff;
    margin: 60px auto 0;
    max-width: 992px;
    @media screen and (min-width: 998px) {
        argin: 110px auto;
    }
    :global {
        li {
            align-items: center;
            display: flex;
            flex-flow: column;
            font-size: 20px;
            gap: 20px;
            margin-bottom: 70px;
            text-align: left;
            @media screen and (min-width: 998px) {
                flex-flow: row;
                gap: 0;
            }
        }
        .number {
            @apply font-mono text-brown-300;
            display: flex;
            text-align: left;
            width: 100%;
            @media screen and (min-width: 998px) {
                width: 70px;
            }
        }
        .title {
            display: flex;
            text-align: left;
            width: 100%;
            @media screen and (min-width: 998px) {
                width: calc(50% - 70px);
            }
        }
        p {
            @apply text-gray-300;
            text-align: left;
            width: 100%;
            @media screen and (min-width: 998px) {
                width: 50%;
            }
        }
    }
}

@keyframes FadeFromBottomWithDelay { 
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  50% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

