
          @import "src/styles/global";
        
.Wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    padding-top: 40px;
    position: relative;
    width: 100%;

    @media screen and (max-width: 767px) {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(100% - 40px);
    }
}

.SlickWrapper {
    @apply bg-black;
    border-radius: 30px;
    padding-top: 50px;
    overflow: hidden;
    @media screen and (min-width: 998px) {
        padding-top: 0px;
    }
    &.runAnimation {
        animation: testimonialsBottomFade 0.9s linear;

        @keyframes testimonialsBottomFade {
            0% {
                transform: translateY(20%);
            }

            100% {
                transform: translateY(0%);
            }
        }
    }

    &.row3 {
        margin: 0 auto;
        max-width: 1331px !important;
    }

    .full {
        padding-bottom: 50px;
        max-width: 100% !important;
        margin: 0 auto;

    }
}

.TestimonialsSliderContainer {
    :global {    
        .slick-list {
            overflow: visible;
        }

        // ${sliderDots("#242E35")};
        .slick-dots li button:before {
            font-size: 18px !important;
            opacity: 1;
            color: transparent;
            border: 2px solid #fff;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            content: "";
        }

        .slick-dots li.slick-active button:before {
            background-color: #fff;
            color: #fff;
            opacity: 1;
        }
        // removeOutline
        .slideWrap:focus,
        .slideWrap:active {
            outline: none;
        }
        
        .slick-next {
            right: 25px;
        }

        .slick-prev {
            left: 25px;
            z-index: 1;
        }

        .slick-slide div {
            outline: 0;
        }
        .slick-track {
            display: flex;
        }
        .slick-slide {
            min-width: 400px;
            @media screen and (max-width: 991px) {
                min-width: 330px;
                width: 330px;
            }
        }
        .slick-arrow {
            font-size: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-arrow:before {
            font-size: 20px;
        }
        
        .slick-next {
            right: 60px;
            z-index: 2;
        }

        .slick-prev {
            left: 20px;
            z-index: 2;
        }

        .slick-prev:before, .slick-next:before {
            @apply font-vinovestmedium;
            background-color: #000;
            border: 1px solid #29292E;
            border-radius: 10px;
            font-size: 20px !important;
            line-height: 1;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            padding: 10px 12px;
            position: absolute;
            z-index: 2;
        }
        .slick-next:before {
            content: '→';
        }
        .slick-prev:before {
            content: '←';
        }

        .slick-dots {
            bottom: -10px !important;
            li {
                button {
                    &:before {
                        background-color: #29292E;
                        border-color: #29292E;
                    }
                }
                &.slick-active {
                    button {
                        &:before {
                            background-color: #fff;
                            border-color: #fff;
                        }
                    }
                }
            }
        }

    }
}


.SlideContainer {
    @apply font-roslindale;
    align-items: start;
    background-color: #000;
    color: #c5d5e4;
    display: flex;
    font-size: 26px;
    font-weight: 500;
    line-height: 150%;
    margin-right: 20px;
    margin-left: 20px;
    padding: 48px;
    position: relative;
    &.clean{
        border: 1px solid #000;
        border-radius: 8px;
        flex-flow: column;
        padding: 40px;
        position: relative;
        @media screen and (max-width: 991px) {    
            padding: 20px 0 50px;
        }
        :global {
            .message {
                text-align: center;
                width: 100%;
                img {
                    margin: 0 auto;
                }
            }
            .description {
                @apply font-vinovestmedium text-white;
                font-size: 20px;
                font-weight: 400;
                line-height: 1.3;
                margin: 30px auto 40px !important;
                max-width: 784px;
                text-align: center;
                @media screen and (min-width: 998px) {    
                    font-size: 24px;
                }
            }
            .profile {
                text-align: center;
                width: 100%;
            }
            .bottomText {
                @apply font-mono;
                color: #fff;
                font-size: 14px;
                line-height: 1.3;
                text-align: center;
            }
        }
    }
}