
          @import "src/styles/global";
        
.Wrapper {
    @apply border-gray-750;
    border-style: solid dashed;
    border-width: 1px 0;
    display: flex;
    margin: 50px auto;
    max-width: 1484px;
    padding: 50px 20px;
    :global {
        h2 {
            @apply text-white;
            font-size: 18px;
            opacity: .5;
            white-space: nowrap;
            text-align: center;
            @media screen and (min-width: 998px) {
                text-align: left;
            }
        }
    }
}

.ImgsWrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 0;
    justify-content: center;
}

.ImgContainer {
    max-height: 28px;
    width: 50%;
    @media screen and (min-width: 998px) {
        width: 12%;
    }
    img {
        max-height: 28px;
    }
}